<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="flex gap-2 items-center mt-5 mb-5">
            <h3 class="text-xl font-semibold">Lead time (days):</h3>
            <a-input-number :min="1" v-model:value="leadTime" @blur="updateLeadTime"/>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full relative">
            <div class="flex items-center justify-center h-screen">
                <div class="left border-r border-gray-200 h-screen flex-1 overflow-auto p-3">
                    <h4 class="uppercase font-bold">Picked to be made</h4>
                    <picked-to-be-made ref="pickedToBeMade" @reload="reloadNeedsPicking" :leadTime="leadTime"></picked-to-be-made>
                </div>

                <div class="right h-full flex-1 overflow-auto p-3">
                    <div>
                        <h4 class="uppercase font-bold">Picked Missing Items</h4>

                        <div class="mb-5">
                            <!--<a-table :columns="columns2" :data-source="results2" bordered :pagination="false" :rowKey="record => record.sku" emptyText="">
                                <template #action="{ record }">
                                    <a-button danger size="small" @click="removeRowMissing(record)">Remove</a-button>
                                </template>

                                <template #expandedRowRender="{ record }" class="p-0">
                                    <table class="w-full">
                                        <tbody class="border border-gray-300">
                                        <tr v-for="(child, index) in JSON.parse(record.child_skus)" :key="child.id">
                                            <td :class="{'border-t border-gray-100': index !== 0}">
                                                {{ child.sku }}
                                            </td>

                                            <td :class="{'border-t border-gray-100': index !== 0}">
                                                {{ child.qty }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </a-table>-->
                            <picked-missing-items/>
                        </div>

                        <a-form ref="formRef" name="dynamic_form_nest_item" :model="dynamicValidateForm" @finish="onFinish" style="border: 1px solid #f0f0f0; background:white; padding: 10px !important" class="p-2">
                            <div class="flex items-center gap-4">
                                <a-form-item name="parent" label="Parent" :rules="[{ required: true, message: 'Missing parent' }]" style="margin-bottom: 10px !important">
                                    <a-input v-model:value="dynamicValidateForm.parent"/>
                                </a-form-item>

                                <a-form-item name="qty" label="Qty" style="margin-bottom: 10px !important">
                                    <a-input v-model:value="dynamicValidateForm.qty" type="number"/>
                                </a-form-item>
                            </div>

                            <a-space v-for="(child, index) in dynamicValidateForm.children" :key="child.id" style="display: flex; margin-bottom: 5px" align="baseline">
                                <a-form-item :name="['children', index, 'sku']" label="SKU" :rules="{ required: true, message: 'Missing child', }" style="margin-bottom: 10px !important">
                                    <a-input v-model:value="child.sku"/>
                                </a-form-item>
                                <a-form-item label="Qty" :name="['children', index, 'qty']" :rules="{ required: false, message: 'Missing qty', }" style="margin-bottom: 10px !important">
                                    <a-input v-model:value="child.qty" type="number"/>
                                </a-form-item>
                                <MinusCircleOutlined @click="removeChild(child)"/>
                            </a-space>

                            <a-form-item style="margin-bottom: 10px !important">
                                <a-button type="dashed" block @click="addChild">
                                    <PlusOutlined/>
                                    Add Child Item
                                </a-button>
                            </a-form-item>
                            <a-form-item style="margin-bottom: 10px !important">
                                <a-button type="primary" html-type="submit">Submit</a-button>
                            </a-form-item>
                        </a-form>

                        <div class="mt-4">
                            <h4 class="uppercase font-bold">Needs Picking</h4>
                            <needs-picking ref="needsPicking" @reload="reloadPickedToBeMade"></needs-picking>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons-vue';
import {defineComponent, reactive} from 'vue';
import pickedToBeMade from "../components/pickedToBeMade";
import pickedMissingItems from "../components/pickedMissingItems";
import needsPicking from "../components/needsPicking";

export default defineComponent({
    name: 'App',
    data() {
        return {
            leadTime: 1,
            newSku: "",
            newQty: "",
            results2: [{
                sku: "",
                qty: "",
                items: []
            }],
            columns1: [
                {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku',
                    slots: {
                        customRender: 'sku',
                    },
                },
                {
                    title: 'Qty',
                    dataIndex: 'qty',
                    key: 'qty',
                    slots: {
                        customRender: 'qty',
                    },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    slots: {
                        customRender: 'action',
                    },
                    width: "100px"
                }
            ],
            columns2: [
                {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku'
                },
                {
                    title: 'Qty',
                    dataIndex: 'qty',
                    key: 'qty'
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    slots: {
                        customRender: 'action',
                    },
                    width: "100px"
                }
            ],
            dynamicValidateForm: reactive({
                children: [],
                parent: undefined,
                qty: undefined,
            }),
            editingKey: '',
        }
    },
    components: {
        MinusCircleOutlined,
        PlusOutlined,
        pickedToBeMade,
        needsPicking,
        pickedMissingItems
    },
    mounted() {
        this.getLeadTime();
        //this.getData()

        //this.getDataMissing()
    },
    computed: {
        count() {
            return this.results.length + 1
        }
    },
    methods: {
        updateLeadTime() {
          console.log(this.leadTime)
            axios.post(window.api_url + '/api/dispatch/fab/leadtime/update', {
                days: this.leadTime
            }).then(() => {
                this.$message.success("Updated lead time");
            }).catch(err => {
                console.log(err)
            })
        },
        getLeadTime() {
          console.log(this.leadTime)
            axios.get(window.api_url + '/api/dispatch/fab/leadtime').then((resp) => {
                this.leadTime = parseInt(resp.data.data)
            }).catch(err => {
                console.log(err)
            })
        },
        getData() {
            this.results = [];
            axios.get(window.api_url + '/api/dispatch/fab/picked-to-be-made').then(resp => {
                this.results = resp.data;
            }).catch(err => {
                console.log(err)
            })
        },
        addRow() {
            let qty = this.newQty
            let sku = this.newSku
            if (sku !== "") {
                axios.post(window.api_url + '/api/dispatch/fab/picked-to-be-made', {
                    sku: sku,
                    qty: qty
                }).then((resp) => {
                    if (resp.data.success) {
                        this.getData();
                        this.newSku = "";
                        this.newQty = "";
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                });
            }
        },
        removeRow(data) {
            const conf = confirm('Are you sure you want to remove?');
            if (conf) {
                axios.delete(window.api_url + '/api/dispatch/fab/picked-to-be-made?id=' + data.id).then(resp => {
                    if (resp.data.success) {
                        this.getData();
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                })
            }
        },
        getDataMissing() {
            axios.get(window.api_url + '/api/dispatch/fab/missing-items').then(resp => {
                this.results2 = resp.data;
            }).catch(err => {
                console.log(err)
            })
        },
        addChild() {
            this.dynamicValidateForm.children.push({
                sku: undefined,
                qty: undefined,
                id: Date.now(),
            });
        },
        removeChild(item) {
            let index = this.dynamicValidateForm.children.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateForm.children.splice(index, 1);
            }
        },
        onFinish() {
            console.log(this.dynamicValidateForm);
            axios.post(window.api_url + '/api/dispatch/fab/missing-items', {
                form: this.dynamicValidateForm
            }).then((resp) => {
                console.log(resp.data)
                if(resp.data.success) {
                    this.dynamicValidateForm.parent = undefined
                    this.dynamicValidateForm.qty = undefined
                    this.dynamicValidateForm.children = []
                    this.getDataMissing()
                }
            }).catch(err => {
                alert('An error has occurred: ' + err)
            })
        },
        removeRowMissing(data) {
            const conf = confirm('Are you sure you want to remove?');
            if (conf) {
                axios.delete(window.api_url + '/api/dispatch/fab/missing-items?id=' + data.id).then(resp => {
                    if (resp.data.success) {
                        this.getDataMissing();
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                })
            }
        },
        reloadNeedsPicking() {
            this.$refs.needsPicking.getData()
        },
        reloadPickedToBeMade() {
            this.$refs.pickedToBeMade.getData()
        }
    },
    watch: {
        "dynamicValidateForm.parent"() {
            this.dynamicValidateForm.children = [];
        }
    }
})
</script>

<style lang="scss">
td[colspan="3"] {
    padding: 0 !important;
    tr td {
        padding: 10px !important;
    }
}



.editable-cell {
    position: relative;
    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
        padding-right: 24px;
    }

    .editable-cell-text-wrapper {
        padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
        position: absolute;
        right: 0;
        width: 20px;
        cursor: pointer;
    }

    .editable-cell-icon {
        margin-top: 4px;
        display: none;
    }

    .editable-cell-icon-check {
        line-height: 28px;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
        color: #108ee9;
    }

    .editable-add-btn {
        margin-bottom: 8px;
    }
}
.editable-cell:hover .editable-cell-icon {
    display: inline-block;
}
.ant-btn-primary {
    background: #1890ff !important;
}
</style>
