<template>
    <table class="pnd-table w-full border border-gray-200">
        <thead>
        <tr>
            <th v-for="(column, index) in columns" :key="index" class="bg-gray-50 border-b border-gray-200 py-4 px-2" :class="{'border-r border-gray-200': index < columns.length}">{{column.title}}</th>
        </tr>
        </thead>

        <draggable v-model="dataSource" tag="tbody" group="fab" item-key="id" handle=".handle" :animation="180" :delay="0" @end="endDrop">
            <template #item="{element}">
                <tr class="border-b border-gray-200" :class="getClasses(element)">
                    <td class="p-2 text-center border-r border-gray-200 flex items-center justify-center"><div class="handle"><table-outlined /></div></td>
                    <td class="p-2 border-r border-gray-200">
                        <a-input v-if="editableData[element.id]" v-model:value="editableData[element.id]['sku']" style="margin: -5px 0"/>
                        <template v-else>
                            {{ element.sku }}
                        </template>
                    </td>
                    <td class="p-2 border-r border-gray-200">
                        <a-input v-if="editableData[element.id]" v-model:value="editableData[element.id]['qty']" style="margin: -5px 0"/>
                        <template v-else>
                            {{ element.qty }}
                        </template>
                    </td>

                    <td class="p-2 border-r border-gray-200">
                        {{ formatDate(element.added_date) }}
                    </td>
                    <td class="p-2">
                        <div class="editable-row-operations flex items-center gap-2">
                            <span v-if="editableData[element.id]" class="flex gap-2">
                                <a-button type="primary" @click="save(element.id)" size="small">Save</a-button>
                                <a-popconfirm title="Sure to cancel?" @confirm="cancel(element.id)">
                                    <a-button danger size="small">Cancel</a-button>
                                </a-popconfirm>
                            </span>
                            <span v-else>
                                <a-button @click="edit(element.id)" size="small">Edit</a-button>
                            </span>
                            <a-button v-if="!editableData[element.id]" danger size="small" @click="removeRow(element)">Remove</a-button>
                        </div>
                    </td>
                </tr>
            </template>
        </draggable>

        <tfoot>
        <tr class="bg-gray-50">
            <th colspan="6" class="py-2 px-2">
                <div class="flex gap-3">
                    <a-input class="flex-1" placeholder="SKU..." v-model:value="newSku"></a-input>
                    <a-input class="flex-1" placeholder="Qty..." v-model:value="newQty"></a-input>
                    <a-button style="width:150px" @click="addRow">Add Row</a-button>
                </div>
            </th>
        </tr>
        </tfoot>
    </table>



    <a-table v-if="0 === 1" ref="table" id="pickedToBeMade" :columns="columns" :data-source="dataSource" bordered :pagination="false" :rowKey="record => record.id">
        <template #customReorder class="relative">
            <div class="handle"><table-outlined /></div>
        </template>

        <template v-for="col in ['sku', 'qty']" #[col]="{ text, record }" :key="col">
            <div>
                <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0"/>
                <template v-else>
                    {{ text }}
                </template>
            </div>
        </template>

        <template #action="{ record }">
            <div class="editable-row-operations flex items-center gap-2">
                <span v-if="editableData[record.id]" class="flex gap-2">
                    <a-button type="primary" @click="save(record.id)" size="small">Save</a-button>
                    <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">
                        <a-button danger size="small">Cancel</a-button>
                    </a-popconfirm>
                </span>

                <span v-else>
                    <a-button @click="edit(record.id)" size="small">Edit</a-button>
                </span>
                <a-button v-if="!editableData[record.id]" danger size="small" @click="removeRow(record)">Remove</a-button>
            </div>
        </template>

        <template #footer>
            <div class="flex gap-3">
                <a-input class="flex-1" placeholder="SKU..." v-model:value="newSku"></a-input>
                <a-input class="flex-1" placeholder="Qty..." v-model:value="newQty"></a-input>
                <a-button style="width:150px" @click="addRow">Add Row</a-button>
            </div>
        </template>
    </a-table>
</template>

<script>
import axios from 'axios';
import { TableOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash-es';
import draggable from 'vuedraggable';
import moment from "moment/moment";
export default defineComponent({
    name: "pickedToBeMade",
    props: {
        leadTime: {
            type: Number,
        }
    },
    components: {
        TableOutlined,
        draggable
    },
    mounted() {
        this.getData();
        //this.rowDrop();
    },
    data() {
        return {
            columns: [
                {
                    title: '',
                    slots: {
                        customRender: 'customReorder',
                    }
                },
                {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku',
                    slots: {
                        customRender: 'sku',
                    },
                },
                {
                    title: 'Qty',
                    dataIndex: 'qty',
                    key: 'qty',
                    slots: {
                        customRender: 'qty',
                    },
                },
                {
                    title: 'Added',
                    dataIndex: 'added_date',
                    key: 'added_date',
                    slots: {
                        customRender: 'added',
                    },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    slots: {
                        customRender: 'action',
                    },
                    width: "100px"
                }
            ],
            dataSource: [],
            newSku: "",
            newQty: "",
            editableData: {},
            sortable: null,
        }
    },
    emits: ['reload'],
    methods: {
        edit(key) {
            this.editableData[key] = cloneDeep(this.dataSource.filter(item => key === item.id)[0]);
        },
        save(key) {
            const obj = this.dataSource.filter(item => key === item.id)[0];
            Object.assign(this.dataSource.filter(item => key === item.id)[0], this.editableData[key]);
            delete this.editableData[key];
            axios.post(window.api_url + '/api/dispatch/fab/picked-to-be-made/update', {
                id: obj.id,
                sku: obj.sku,
                qty: obj.qty,
                sort_order: obj.sort_order
            }).then(response => {
                console.log(response.data)
                this.getData();
            })
            .catch(e => {
                console.log(e)
            });
        },
        cancel(key) {
            delete this.editableData[key];
        },
        getData() {
            axios.get(window.api_url + '/api/dispatch/fab/picked-to-be-made').then(resp => {
                this.dataSource = resp.data;
            }).catch(err => {
                console.log(err)
            })
        },
        addRow() {
            let qty = this.newQty;
            let sku = this.newSku;
            if (sku !== "") {
                axios.post(window.api_url + '/api/dispatch/fab/picked-to-be-made', {
                    sku: sku,
                    qty: qty
                }).then((resp) => {
                    if (resp.data.success) {
                        this.getData();
                        this.newSku = "";
                        this.newQty = "";
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                });
            }
        },
        removeRow(data, showConf = true) {
            let conf;
            if(showConf) {
                conf = confirm('Are you sure you want to remove?');
            } else {
                conf = true;
            }
            if (conf) {
                axios.delete(window.api_url + '/api/dispatch/fab/picked-to-be-made?id=' + data.id).then(resp => {
                    if (resp.data.success) {
                        this.getData();
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                })
            }
        },
        rowDrop() {
            const tbody = document.querySelector("#pickedToBeMade .ant-table-body tbody");
            const self = this;
            this.sortable = window.Sortable.create(tbody, {
                handle: '.handle',
                animation: 180,
                delay: 0,
                group: "test",
                onEnd(evt) {
                    const currRow = self.dataSource.splice(evt.oldIndex, 1)[0];
                    if(evt.pullMode) {
                        if(currRow !== undefined) {
                            self.addToNeedsPicking(currRow, evt.newIndex);
                        }
                    } else {
                        //const currRow = self.dataSource.splice(oldIndex, 1)[0];
                        self.dataSource.splice(evt.newIndex, 0, currRow);
                        //self.$forceUpdate();
                        var parts = [];
                        self.dataSource.forEach(element => {
                            parts.push({"id": element.id});
                        });
                        self.onPartReorder(parts);
                    }
                },
            });
        },
        onPartReorder(parts) {
            axios.post(window.api_url + '/api/dispatch/fab/picked-to-be-made/update/order', {
                parts: parts
            }).then(response => {
                if(response.data.success) {
                    this.getData()
                } else {
                    alert('Ordering failed')
                }
            })
            .catch(e => {
                console.log(e)
            });
        },
        addToNeedsPicking(data, newIndex) {
            let qty = data.qty;
            let sku = data.sku;
            if (sku !== "") {
                axios.post(window.api_url + '/api/dispatch/fab/needs-picking', {
                    sku: sku,
                    qty: qty,
                    index: newIndex,
                }).then((resp) => {
                    if (resp.data.success) {
                        this.removeRow(data, false);
                        this.$emit('reload');
                        this.getData();
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                });
            }
        },
        endDrop(evt) {
            const currRow = evt.item._underlying_vm_;
            if(evt.pullMode) {
                if(currRow !== undefined) {
                    this.addToNeedsPicking(currRow, evt.newIndex);
                }
            } else {
                const parts = [];
                this.dataSource.forEach(element => {
                    parts.push({"id": element.id});
                });
                this.onPartReorder(parts);
            }
        },
        formatDate(date) {
            return moment(date).format('DD/MM');
        },
        leadTimePercent(element) {
            const start = moment(element.added_date);
            const end = moment(element.added_date).add(this.leadTime, 'days');
            return this.percentageOfDate(start, end);
        },
        percentageOfDate(startDate, endDate) {
            var now = moment(); // Current date
            var start = moment(startDate, 'YYYY-MM-DD'); // Adjust format according to your input date format
            var end = moment(endDate, 'YYYY-MM-DD'); // Adjust format according to your input date format
            var total = end.diff(start, 'seconds'); // Total time difference in seconds
            var elapsed = now.diff(start, 'seconds'); // Elapsed time difference in seconds
            const perc = (elapsed / total) * 100;
            return perc
        },
        getClasses(element) {
            const perc = this.leadTimePercent(element)
            if(perc >= 75) {return "bg-red-300"}
            if(perc > 25 && perc < 75) {return "bg-yellow-200"}
            if(perc <= 25) {return "bg-green-200"}
        }
    },
});
</script>

<style>
.ant-table-row td {
    position: relative;
}
.ant-table-row td .handle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
