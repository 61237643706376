<template>
    <a-table :columns="columns2" :data-source="dataSource" bordered :pagination="false" :rowKey="record => record.sku" emptyText="">


        <template #sku="{ record }">
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id]['sku']" style="margin: -5px 0"/>
            <template v-else>
                {{ record.sku }}
            </template>
        </template>

        <template #qty="{ record }">
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id]['qty']" style="margin: -5px 0"/>
            <template v-else>
                {{ record.qty }}
            </template>
        </template>

        <template #action="{ record }">
            <span v-if="editableData[record.id]" class="flex gap-2">
                <a-button type="primary" @click="save(record.id)" size="small">Save</a-button>
                <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">
                    <a-button danger size="small">Cancel</a-button>
                </a-popconfirm>
            </span>
            <span v-else>
                <a-button @click="edit(record.id)" size="small">Edit</a-button>
            </span>

            <a-button v-if="!editableData[record.id]" class="ml-3" danger size="small" @click="removeRowMissing(record)">Remove</a-button>
        </template>

        <template #expandedRowRender="{ record }" class="p-0">
            <table class="w-full">
                <tbody class="border border-gray-300">
                    <tr v-for="(child, index) in record.child_skus" :key="child.id">
                        <td :class="{'border-t border-gray-100': index !== 0}">
                            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id].child_skus[index].sku"></a-input>
                            <template v-else>
                                {{child.sku}}
                            </template>
                        </td>

                        <td :class="{'border-t border-gray-100': index !== 0}">
                            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id].child_skus[index].qty"></a-input>
                            <template v-else>
                                {{child.qty}}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
    </a-table>
</template>

<script>
import axios from "axios";
import { cloneDeep } from 'lodash-es';
export default {
    data() {
        return {
            columns2: [
                {
                    title: 'SKU',
                    dataIndex: 'sku',
                    key: 'sku',
                    slots: {
                        customRender: 'sku',
                    },
                },
                {
                    title: 'Qty',
                    dataIndex: 'qty',
                    key: 'qty',
                    slots: {
                        customRender: 'qty',
                    },
                },
                {
                    title: 'Action',
                    dataIndex: 'action',
                    key: 'action',
                    slots: {
                        customRender: 'action',
                    },
                    width: "200px"
                }
            ],
            dataSource: [{
                sku: "",
                qty: "",
                items: []
            }],

            newSku: "",
            newQty: "",
            editableData: {},
            sortable: null,
        }
    },
    mounted() {
        this.getDataMissing()
    },
    methods: {
        getDataMissing() {
            axios.get(window.api_url + '/api/dispatch/fab/missing-items').then(resp => {
                this.dataSource = resp.data;
            }).catch(err => {
                console.log(err)
            })
        },
        removeRowMissing(data) {
            const conf = confirm('Are you sure you want to remove?');
            if (conf) {
                axios.delete(window.api_url + '/api/dispatch/fab/missing-items?id=' + data.id).then(resp => {
                    if (resp.data.success) {
                        this.getDataMissing();
                    } else {
                        alert('An error has occurred')
                    }
                }).catch(err => {
                    alert('An error has occurred: ' + err)
                })
            }
        },
        edit(key) {
            this.editableData[key] = cloneDeep(this.dataSource.filter(item => key === item.id)[0]);
        },
        save(key) {
            const obj = this.dataSource.filter(item => key === item.id)[0];
            Object.assign(this.dataSource.filter(item => key === item.id)[0], this.editableData[key]);
            delete this.editableData[key];
            axios.post(window.api_url + '/api/dispatch/fab/missing-items/update', {
                id: obj.id,
                sku: obj.sku,
                qty: obj.qty,
                child_skus: obj.child_skus
            }).then(response => {
                console.log(response.data)
                this.getDataMissing();
            })
            .catch(e => {
                console.log(e)
            });
        },
        cancel(key) {
            delete this.editableData[key];
        },
    }
}
</script>
